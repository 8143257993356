import React from 'react';
import PropTypes from 'prop-types';
import {
  useTheme,
  Box,
  Typography,
  Stack,
  Rating,
  Button,
} from '@mui/material';
import Iconify from 'components/Iconify';

const Review = () => {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Box>
          <Box marginBottom={4} textAlign={'left'}>
            <Typography
              variant="h4"
              component="h3"
              gutterBottom
              sx={{
                fontWeight: 700,
                marginTop: theme.spacing(1),
              }}
            >
              Nos clients nous font confiance !
            </Typography>
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'flex-start'}
              spacing={1}
              useFlexGap
              flexWrap="wrap"
            >
              <Stack>
                <Typography component={'p'} variant="h6" color={'warning.main'}>
                  1 056 avis
                </Typography>
              </Stack>
              <Stack>
                <Box display={'flex'} justifyContent={'flex-start'}>
                  <Rating
                    name="half-rating-read"
                    defaultValue={4.6}
                    precision={0.5}
                    readOnly
                  />
                </Box>
              </Stack>
              <Stack>
                <Typography
                  component={'p'}
                  variant="h6"
                  color={'text.secondary'}
                >
                  sur
                </Typography>
              </Stack>
              <Stack>
                <Box
                  component={'a'}
                  target="_blank"
                  href="https://www.tripadvisor.fr/Restaurant_Review-g187075-d18455887-Reviews-Elite_Steak_House-Strasbourg_Bas_Rhin_Grand_Est.html"
                >
                  <Iconify
                    icon="simple-icons:tripadvisor"
                    width={80}
                    height={100}
                    sx={{ height: 40, color: 'success.main' }}
                  />
                </Box>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
};

Review.propTypes = {
  reviews: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default Review;
