import { styled } from '@mui/material/styles';
import Layout from 'layouts';
import Page from 'components/Page';
import { HomeHero } from '../sections/home';

const ContentStyle = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

HomePage.getLayout = function getLayout(page) {
  return <Layout variant="main">{page}</Layout>;
};

export default function HomePage() {
  return (
    <Page title="Restaurant Halal Strasbourg">
      <ContentStyle>
        <HomeHero />
      </ContentStyle>
    </Page>
  );
}
